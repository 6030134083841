a {
	text-decoration: none;
	color: white;
}

a:hover {
	color: white;
	text-decoration: none;
}

.section-label {
	/*font-size: 15px;*/
	font-weight: 500;
}

.step-box {
	background-color: #0099AA;
	padding: 5px 10px;
	color: white;
	/*font-size: 15px;*/
	font-weight: 600;
	margin-right: 7px;
}

.asset-item-row {
	background-color: #F0F1F2;
	padding: 5px 10px;
	margin-bottom: 1px;
	display: flex;
	justify-content: space-between;
}

.asset-item-row span {
	cursor: pointer;
	color: #417AB7;
	/*color: #007bff;*/
}

.quiz-section {
	background-color: #F0F1F2;
	text-align: center;
	padding: 20px;
}

.quiz-section button {
	background-color: #337AB7;
	font-weight: 800;
	/*font-size: 11px;*/
}

.video-player-container {
	display: flex;
	justify-content: center;
	position: relative;
	padding-top: 56.25%;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}
