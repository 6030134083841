.cover-page {
	position: relative;
}

.cover-page p {
	font-weight: 700;
}

.background-logo {
	position: absolute;
	width: 100%;
	z-index: -1;
	opacity: 0.3;
	background-image: url('../new-logo.png');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}
