.quiz-container {
	display: flex;
	/*height: 100vh;*/
	background-image: url('../new-logo.png');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.quiz-card {
	width: 100%;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
}

.quiz-card:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
