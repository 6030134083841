/*html {
  font-size: 1rem;
}*/

body {
  margin: 0;
  padding: 0;
  /*font-family: "Open Sans", sans-serif;*/
  /*font-size: 16px;*/
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;*/
}

/*@include media-breakpoint-up(xs) {
	html {
		font-size: 0.2rem;
	}
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}*/

h1, h2, h3, h4, h5, h6 {
  /*font-family: "PT Serif", serif;*/
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}

p {
	color: #7a7a7a;
}

/*Extra Small devices (575px and down)*/
@media (max-width: 575px) {
	html {
		font-size: 0.6rem
	}

	.checkmark {
		width: 15px;
		height: 15px;
	}

	.checkmark-container {
		padding-left: 20px;
	}

	.checkmark-container .checkmark:after {
		top: 1px;
		left: 4px;
	}
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
	html {
		font-size: 0.7rem;
	}
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
	html {
		font-size: 0.8rem
	}

	.checkmark {
		width: 20px;
		height: 20px;
	}

	.checkmark-container {
		padding-left: 30px;
	}

	.checkmark-container .checkmark:after {
		top: 2px;
		left: 6px;
	}

	.section-label,
	.step-box {
		font-size: 15px;
	}

	.quiz-section button {
		font-size: 11px;
	}
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
	html {
		font-size: 1rem;
	}
}

.loading-logo-background {
  background-color: rgb(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 100;
  display: flex;
}

.loading-logo {
  animation: App-logo-spin infinite 2s linear;
  /*height: 40vmin;*/
  pointer-events: none;
  width: 80px;
  height: 80px;
  margin: auto;
}

.loading-logo img {
  max-height: 100%;
  max-width: 100%;
}

.pointer {
	cursor: pointer;
}

.checkmark-container {
  display: block;
  position: relative;
  /*padding-left: 30px;*/
  /*margin-bottom: 12px;*/
  cursor: pointer;
  /*font-size: 15px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  /*margin-right: 7px;*/
}

.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  /*height: 20px;*/
  /*width: 20px;*/
  /*background-color: #eee;*/
  border: 1px solid #C1C1C1;
}

.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark-container input:checked ~ .checkmark {
  background-color: #0a94a1;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

.checkmark-container .checkmark:after {
  /*left: 6px;*/
  /*top: 2px;*/
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
